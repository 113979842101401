<template>
  <form @submit.prevent="submitModal">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Сменить пароль</p>
      </header>
      <div class="card-content">
        <div class="content">
          <b-field
            label="Новый пароль"
            :type="{
              'is-danger': $v.password.$error,
              'is-success': !$v.password.$invalid && $v.password.$dirty,
            }"
            :message="{
              'Некорректно указан пароль': $v.password.$error,
            }"
          >
            <b-input
              placeholder="Введите пароль"
              type="password"
              password-reveal
              required
              v-model.trim="$v.password.$model"
            >
            </b-input>
          </b-field>

          <b-field
            label="Повторить пароль"
            :type="{
              'is-danger': $v.repeatPassword.$error,
              'is-success':
                !$v.repeatPassword.$invalid && $v.repeatPassword.$dirty,
            }"
            :message="{
              'Пароли не совпадают': $v.repeatPassword.$error,
            }"
          >
            <b-input
              placeholder="Введите пароль повторно"
              type="password"
              password-reveal
              required
              v-model.trim="$v.repeatPassword.$model"
            >
            </b-input>
          </b-field>
        </div>
      </div>
      <footer class="card-footer">
        <b-button
          label="Закрыть"
          class="card-footer-item"
          @click="$emit('close')"
        />
        <b-button
          label="Сменить"
          type="is-success"
          class="card-footer-item"
          native-type="submit"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'ModalChangePassword',
  props: ['employeeId'],
  data() {
    return {
      password: '',
      repeatPassword: '',
    };
  },
  methods: {
    async submitModal() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      let success;

      if (this.employeeId !== null && this.employeeId !== undefined) {
        success = await this.$store.dispatch('profile/changeEmployeePassword', {
          password: this.password,
          id: this.employeeId,
        });
      } else {
        success = await this.$store.dispatch('profile/changeUserPassword', {
          password: this.password,
        });
      }

      if (success) {
        this.$emit('close');
      }
    },
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    repeatPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
  beforeDestroy() {
    this.password = '';
    this.repeatPassword = '';
  },
};
</script>

<style lang="scss">
.change-password {
  form {
    min-width: 400px;

    .card {
      .card-footer {
        button {
          border-radius: 0;
        }
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitModal($event)}}},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('b-field',{attrs:{"label":"Новый пароль","type":{
            'is-danger': _vm.$v.password.$error,
            'is-success': !_vm.$v.password.$invalid && _vm.$v.password.$dirty,
          },"message":{
            'Некорректно указан пароль': _vm.$v.password.$error,
          }}},[_c('b-input',{attrs:{"placeholder":"Введите пароль","type":"password","password-reveal":"","required":""},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}})],1),_c('b-field',{attrs:{"label":"Повторить пароль","type":{
            'is-danger': _vm.$v.repeatPassword.$error,
            'is-success':
              !_vm.$v.repeatPassword.$invalid && _vm.$v.repeatPassword.$dirty,
          },"message":{
            'Пароли не совпадают': _vm.$v.repeatPassword.$error,
          }}},[_c('b-input',{attrs:{"placeholder":"Введите пароль повторно","type":"password","password-reveal":"","required":""},model:{value:(_vm.$v.repeatPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.repeatPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.repeatPassword.$model"}})],1)],1)]),_c('footer',{staticClass:"card-footer"},[_c('b-button',{staticClass:"card-footer-item",attrs:{"label":"Закрыть"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('b-button',{staticClass:"card-footer-item",attrs:{"label":"Сменить","type":"is-success","native-type":"submit"}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Сменить пароль")])])}]

export { render, staticRenderFns }
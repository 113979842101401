<template>
  <div class="admin-employees">
    <b-table
      :data="employees"
      :bordered="false"
      :striped="true"
      :narrowed="false"
      :hoverable="true"
      :loading="false"
      :focusable="false"
      :mobile-cards="true"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="lastName" label="Фамилия" v-slot="props">
        {{ props.row.lastName }}
      </b-table-column>

      <b-table-column field="firstName" label="Имя" v-slot="props">
        {{ props.row.firstName }}
      </b-table-column>

      <b-table-column field="patronymic" label="Отчество" v-slot="props">
        {{ props.row.patronymic }}
      </b-table-column>

      <b-table-column field="phone" label="Телефон" v-slot="props">
        {{ props.row.phone }}
      </b-table-column>

      <b-table-column field="email" label="Email" v-slot="props">
        {{ props.row.email }}
      </b-table-column>

      <b-table-column
        field="action"
        label="Действие"
        width="150"
        centered
        v-slot="props"
      >
        <b-button
          size="is-small"
          type="is-info is-light"
          outlined
          @click.prevent="changeEmployeePassword(props.row.id)"
          >Сменить пароль</b-button
        >
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">
          <b-icon icon="user-plus" size="is-medium" type="is-success"></b-icon>
          <b>
            Сотрудники еще не добавлены
            <a href="/create-employee">Добавить сотрудника</a>
          </b>
        </div>
      </template>
      <template #footer v-if="employees.length">
        <div class="has-text-centered">
          <b-icon icon="user-plus" size="is-medium" type="is-success"></b-icon>
          <b>
            <a href="/create-employee">Добавить сотрудника</a>
          </b>
        </div>
      </template>
    </b-table>
    <b-modal
      class="change-password"
      has-modal-card
      v-model="isModalChangePasswordActive"
    >
      <template #default="props">
        <ModalChangePassword :employeeId="employeeId" @close="props.close" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ModalChangePassword from '@/components/profile/ModalChangePassword';

export default {
  name: 'AdminEmployees',
  data() {
    return {
      employeeId: null,
      isModalChangePasswordActive: false,
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('profileInfo');
    this.$store.commit('toggleLoader', false);
  },
  computed: mapState({
    employees: (state) => {
      return state.employee.employees;
    },
  }),
  methods: {
    changeEmployeePassword(id) {
      this.employeeId = id;
      this.isModalChangePasswordActive = true;
    },
  },
  components: { ModalChangePassword },
};
</script>

<style lang="scss">
.admin-employees {
  padding: 10px;

  table {
    .is-empty {
      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
